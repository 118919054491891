<template>
  <layout-vertical>

    <router-view />

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import { $themeConfig } from '@themeConfig'
import useJwt from '@/auth/jwt/useJwt'
import dataRequester from "@/mixins/utils/dataRequester";
import {mainStore} from "@/store/pinia/main.store";
import settingService from "@/mixins/utils/axios/setting.service";

export default {
  mixins: [dataRequester],
  components: {
    LayoutVertical
  },
  data () {
    return {
      showCustomizer: $themeConfig.layout.customizer
    }
  },
  created() {
    this.getLevBalance()
  },
  methods: {
    getLevBalance() {
      this.runApi(settingService.getSettingByKey('lev_balance'), function (result) {
        mainStore().setLevBalance(result.data['value'])
      })
    }
  },
}
</script>
