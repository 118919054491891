import utilsService from "@/mixins/utils/axios/utils.service";
const settingEndpoint = `${process.env.VUE_APP_BASE_URL}/admin/setting`;

class settingService {
    getSettingList () {
        return utilsService.getRequest(`${settingEndpoint}`);
    }
    insertSetting (...args) {
        return utilsService.postRequest(`${settingEndpoint}`, ...args);
    }
    updateSetting (...args) {
        return utilsService.putRequest(`${settingEndpoint}`, ...args);
    }
    deleteSetting (settingId) {
        return utilsService.deleteRequest(`${settingEndpoint}/${settingId}`)
    }
    getSettingByKey (key) {
        return utilsService.getRequest(`${settingEndpoint}/${key}`);
    }
}

export default new settingService()
