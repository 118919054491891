import utilsService from "@/mixins/utils/axios/utils.service";
const userEndpoint = `${process.env.VUE_APP_BASE_URL}/admin/user`;
const userProfileEndpoint = `${process.env.VUE_APP_BASE_URL}/client/profile-info`;

export default {
    listAllUsers () {
        return utilsService.getRequest(`${userEndpoint}/all`);
    },
    listUsersWithCloudService () {
        return utilsService.getRequest(`${userEndpoint}/list`);
    },
    adminUpdateUserInfo (...args) {
        return utilsService.putRequest(`${userEndpoint}/update`, ...args);
    },
    setPassword (...args) {
        return utilsService.putRequest(`${userEndpoint}/set-password`, ...args);
    },
    changePassword (...args) {
        return utilsService.putRequest(`${userEndpoint}/password`, ...args);
    },
    updateUserDetail (...args) {
        return utilsService.putRequest(`${userEndpoint}`, ...args);
    },
    getUserDetail () {
        return utilsService.getRequest(`${userProfileEndpoint}`);
    }
}
